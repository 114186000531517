// Generated by Framer (013b13c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {FiTmcL9b0: {hover: true}};

const cycleOrder = ["FiTmcL9b0", "vkwSQhbgw"];

const serializationHash = "framer-MyRLq"

const variantClassNames = {FiTmcL9b0: "framer-v-15miaep", vkwSQhbgw: "framer-v-iqzu05"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 50, delay: 0, mass: 1, stiffness: 300, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Default: "FiTmcL9b0", Selected: "vkwSQhbgw"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, FcK_TvsYr: title ?? props.FcK_TvsYr ?? "Home", QuWKPWgPR: link ?? props.QuWKPWgPR, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FiTmcL9b0"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, FcK_TvsYr, QuWKPWgPR, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FiTmcL9b0", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={QuWKPWgPR} motionChild nodeId={"FiTmcL9b0"} openInNewTab={false} scopeId={"VAkCbNA1f"} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-15miaep", className, classNames)} framer-10gq4lt`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"FiTmcL9b0"} ref={refBinding} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} variants={{"FiTmcL9b0-hover": {backgroundColor: "var(--token-6839e435-35f3-4ab3-b723-e98c27e525bc, rgba(255, 255, 255, 0.1))"}, vkwSQhbgw: {backgroundColor: "var(--token-6839e435-35f3-4ab3-b723-e98c27e525bc, rgba(255, 255, 255, 0.1))"}}} {...addPropertyOverrides({"FiTmcL9b0-hover": {"data-framer-name": undefined}, vkwSQhbgw: {"data-framer-name": "Selected"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3V0Zml0LXJlZ3VsYXI=", "--framer-font-family": "\"Outfit\", \"Outfit Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-92a1cea2-6d53-45ff-98da-32c110a8c3fc, rgb(255, 255, 255)))"}}>Process</motion.p></React.Fragment>} className={"framer-p2k055"} fonts={["GF;Outfit-regular"]} layoutDependency={layoutDependency} layoutId={"eTRluIcPC"} style={{"--extracted-r6o4lv": "var(--token-92a1cea2-6d53-45ff-98da-32c110a8c3fc, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={FcK_TvsYr} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MyRLq.framer-10gq4lt, .framer-MyRLq .framer-10gq4lt { display: block; }", ".framer-MyRLq.framer-15miaep { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 5px 8px 5px 8px; position: relative; text-decoration: none; width: min-content; }", ".framer-MyRLq .framer-p2k055 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MyRLq.framer-15miaep { gap: 0px; } .framer-MyRLq.framer-15miaep > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-MyRLq.framer-15miaep > :first-child { margin-left: 0px; } .framer-MyRLq.framer-15miaep > :last-child { margin-right: 0px; } }", ".framer-MyRLq.framer-v-iqzu05.framer-15miaep { cursor: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 53.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"vkwSQhbgw":{"layout":["auto","auto"]},"atm5kklSP":{"layout":["auto","auto"]}}}
 * @framerVariables {"FcK_TvsYr":"title","QuWKPWgPR":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVAkCbNA1f: React.ComponentType<Props> = withCSS(Component, css, "framer-MyRLq") as typeof Component;
export default FramerVAkCbNA1f;

FramerVAkCbNA1f.displayName = "nav items 4";

FramerVAkCbNA1f.defaultProps = {height: 27, width: 53.5};

addPropertyControls(FramerVAkCbNA1f, {variant: {options: ["FiTmcL9b0", "vkwSQhbgw"], optionTitles: ["Default", "Selected"], title: "Variant", type: ControlType.Enum}, FcK_TvsYr: {defaultValue: "Home", displayTextArea: false, placeholder: "", title: "Title", type: ControlType.String}, QuWKPWgPR: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerVAkCbNA1f, [{explicitInter: true, fonts: [{family: "Outfit", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4S-EiAou6Y.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})